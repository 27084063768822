import React, { useCallback } from 'react';
import { ReactComponent as IconResources } from '../../../../assets/icons/lettuce/unfi/resources.svg';
import { ReactComponent as IconMain } from '../../../../assets/icons/lettuce/unfi/navrail-home.svg';
import { ReactComponent as IconAnalytics } from '../../../../assets/icons/lettuce/unfi/navrail-analytics.svg';
import { ReactComponent as IconExternalWindow } from '../../../../assets/icons/lettuce/unfi/navrail-on-platform.svg';
import { ReactComponent as IconDeductions } from '../../../../assets/icons/lettuce/unfi/deductions.svg';
import { ExternalLinkTarget } from '../../components/ExternalLink';
import NavRail from '../../components/NavRail';
import {
  ExternalLinkMenuListItem,
  MenuListItem,
  MenuListItemWithPopoverSubMenu,
} from '../../components/NavRail/menuItems';
import { SubMenuType } from '../../components/NavRail/SubMenuPopover';
import { BackdropPredicateContextProvider } from '../../components/NavRail/BackdropPredicateProvider';
import StandardViews, {
  StandardViewsProps,
} from '../../crisp/navigation/AnalyticsMenu/StandardViews';
import { Feature } from '../../../../utils/features';
import useIsFeatureFlagEnabled from '../../common/featureFlags/useIsFeatureFlagEnabled';
import { KnownFlags } from '../../common/featureFlags/KnownFlags';
import { unfiEmbeddedPath } from '../routing/Routes';
import useGetUnfiDashboardGroups from '../useGetUnfiDashboardGroups';
import { resources } from '../Home/lib';
import ResourcePanel from '../../components/ResourcePanel';
import style from './index.module.css';

const DashboardMenuItemsWrapper: React.FC<StandardViewsProps> = props => {
  return (
    <div className={style.dashboardMenuItemsWrapper}>
      <h1>Analytics</h1>
      <StandardViews {...props} />
    </div>
  );
};

const ResourcesMenuItemsWrapper = () => {
  return (
    <div className={style.resourcesMenuItemsWrapper}>
      <h1>Resources</h1>
      <ResourcePanel resources={resources} />
    </div>
  );
};

const UnfiInsightsEmbeddedNav: React.FC = () => {
  const deductionsEnabled = useIsFeatureFlagEnabled(KnownFlags.Deductions);
  const { dashboardGroups, loading: dashboardGroupsLoading } = useGetUnfiDashboardGroups();
  const getPath = (dashboardGroupId: string, dashboardId: string) =>
    unfiEmbeddedPath('UnfiInsightsEmbeddedDashboards')({ dashboardGroupId, dashboardId });
  const dashboardGroupsWithFilteredDashboards = deductionsEnabled
    ? dashboardGroups
    : dashboardGroups.map(g => ({
        ...g,
        dashboards: g.dashboards.filter(d => d.name !== 'Deductions'),
      }));
  return (
    <NavRail alignChildren="center">
      <BackdropPredicateContextProvider useIncludeBackdrop={useCallback(() => true, [])}>
        <ul id="nav-rail-pages" role="menu" className={style.menuItems}>
          <MenuListItem
            Icon={() => <IconMain />}
            label="Main"
            path={unfiEmbeddedPath('UnfiInsightsEmbedded')()}
            featureId={Feature.NavMenuHomepage}
          />

          <MenuListItemWithPopoverSubMenu
            id="dashboardNav"
            RenderContent={DashboardMenuItemsWrapper}
            RenderIcon={() => <IconAnalytics />}
            label="Analytics"
            subMenuType={SubMenuType.Expansive}
            getPath={getPath}
            getConnectorName={() => undefined}
            dashboardGroups={dashboardGroupsWithFilteredDashboards}
            dashboardGroupsLoading={dashboardGroupsLoading}
            ariaLabel="Analytics menu"
          />

          {deductionsEnabled && (
            <MenuListItem
              Icon={() => <IconDeductions />}
              label="Deductions Tool"
              path={unfiEmbeddedPath('UnfiInsightsEmbeddedDeductions')()}
              featureId={Feature.NavMenuDeductions}
            />
          )}
          <MenuListItemWithPopoverSubMenu
            id="resourcesNav"
            RenderContent={ResourcesMenuItemsWrapper}
            RenderIcon={() => <IconResources />}
            label="Resources"
            subMenuType={SubMenuType.Expansive}
            ariaLabel="Resources Menu"
          />
          <ExternalLinkMenuListItem
            Icon={() => <IconExternalWindow />}
            label="Crisp"
            href={window.location.origin}
            target={ExternalLinkTarget.newTab}
            featureId={Feature.NavMenuCrispPlatform}
          />
        </ul>
      </BackdropPredicateContextProvider>
    </NavRail>
  );
};

export default UnfiInsightsEmbeddedNav;
