import { ConnectorApplication } from '../../../../generated/graphql';
import { PartnerAccount } from '../../components/lib/Account';

export type PartnerAccountIdParams = Pick<PartnerAccount, 'partnerAccountId'>;

export type PartnerAccountProps = {
  partnerAccount: PartnerAccount;
};

export enum PartnerType {
  Dot = 'dot',
}

export const PartnerTypeToConnectorApplication: Partial<Record<PartnerType, ConnectorApplication>> =
  {
    [PartnerType.Dot]: ConnectorApplication.Dot,
  };
