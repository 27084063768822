import React from 'react';
import DotLogoPng from '../../../../assets/dot-logo.png';
import { PartnerType } from './index';

type PartnerLogoVariant = 'navrail' | 'banner';

const NavrailLogoStyles: Partial<Record<PartnerType, object>> = {
  [PartnerType.Dot]: {
    width: '52px',
    height: '52px',
  },
};

const BannerLogoStyles: Partial<Record<PartnerType, object>> = {
  [PartnerType.Dot]: {
    width: '100px',
    height: '100px',
  },
};

const LogoSrc: Record<PartnerType, string> = {
  [PartnerType.Dot]: DotLogoPng,
};

type PartnerLogoProps = {
  partnerType: PartnerType;
  variant: PartnerLogoVariant;
};

const PartnerLogo: React.FC<PartnerLogoProps> = ({ variant, partnerType }) => {
  const style = (variant === 'navrail' ? NavrailLogoStyles : BannerLogoStyles)[partnerType];
  return <img src={LogoSrc[partnerType]} alt="logo" style={style} />;
};

export default PartnerLogo;
