import React, { createContext, useContext } from 'react';
import { ConnectorApplication } from '../../../../generated/graphql';
import { FCC } from '../../../../utils/types';
import { partnerAccount, PartnerAccountWithType } from '../../components/lib/Account';
import { PartnerType, PartnerTypeToConnectorApplication } from './index';

type PartnerContextInterface = {
  partnerAccount: PartnerAccountWithType;
  // Some partners may be associated with a connector application. This helps us
  // render a default logo for the partner workspaces.
  partnerConnectorApplication?: ConnectorApplication;
};

export const PartnerContext = createContext<PartnerContextInterface>({
  partnerAccount: partnerAccount('', PartnerType.Dot),
  partnerConnectorApplication: undefined,
});

type PartnerContextProviderProps = Pick<PartnerContextInterface, 'partnerAccount'>;

const PartnerContextProvider: FCC<PartnerContextProviderProps> = ({ children, partnerAccount }) => {
  return (
    <PartnerContext.Provider
      value={{
        partnerAccount,
        partnerConnectorApplication: PartnerTypeToConnectorApplication[partnerAccount.partnerType],
      }}
    >
      {children}
    </PartnerContext.Provider>
  );
};

export default PartnerContextProvider;

export const usePartnerContext = () => useContext(PartnerContext);
export const usePartnerAccount = () => useContext(PartnerContext).partnerAccount;
