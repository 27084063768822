import React, { ReactNode } from 'react';
import { ExternalLinkTarget } from '../ExternalLink';
import Card from '../Card';
import Title from '../Title';
import { Feature, trackFeature } from '../../../../utils/features';
import { Resource } from './lib';
import style from './index.module.css';

type ResourcesCardProps = {
  icon: ReactNode;
  title: string;
  subTitle: string;
  buttonText: string;
  to?: string;
  href?: string;
  target?: ExternalLinkTarget;
  featureId?: Feature;
  onClick?: () => void;
};

const ResourcesCard: React.FC<ResourcesCardProps> = ({
  icon,
  title,
  subTitle,
  buttonText,
  to,
  href,
  target,
  featureId,
  onClick,
}) => {
  return (
    <Card
      to={to}
      href={href}
      target={target}
      className={style.resourceCard}
      onClick={() => {
        onClick?.();
        featureId && trackFeature(featureId);
      }}
    >
      <div className={style.iconContainer}>{icon}</div>
      <div className={style.content}>
        <Title className={style.title}>{title}</Title>
        <div className="subtitle">{subTitle}</div>
        <div className={style.button}>{buttonText}</div>
      </div>
    </Card>
  );
};

type ResourcePanelProps = {
  resources: Resource[];
};

const ResourcePanel: React.FC<ResourcePanelProps> = ({ resources }) => {
  return (
    <div className={style.additionalResourcesContainer}>
      {resources.map(resource => (
        <ResourcesCard key={resource.title} {...resource} href={resource.url} />
      ))}
    </div>
  );
};

export default ResourcePanel;
