import React, { ReactNode } from 'react';
import { ReactComponent as IconOpenNewWindow } from '../../../../assets/icons/open-new-window.svg';
import styles from './index.module.css';

export type ExternalLinkProps = {
  children: React.ReactNode;
  className?: string;
  target?: ExternalLinkTarget;
  Icon?: ReactNode;
} & Pick<React.AnchorHTMLAttributes<void>, 'href'>;

export enum ExternalLinkTarget {
  newTab = '_blank',
  knowledgeBase = 'crispKnowledgeBase',
  academy = 'crispAcademy',
  dataCatalog = 'dataCatalog',
  salesforceAccount = 'salesforceAccount',
  dataCatalogPrint = 'dataCatalogPrint',
}

export default function ExternalLink({
  children,
  className,
  href,
  target = ExternalLinkTarget.newTab,
  Icon = <IconOpenNewWindow />,
}: ExternalLinkProps) {
  const rels = ['external', 'noopener'];

  if (href && href.startsWith('http') && !href.includes('gocrisp.com')) {
    rels.push('noreferrer');
  }

  return (
    <a
      href={href}
      target={target}
      rel={rels.join(' ')}
      style={{ wordBreak: 'break-word' }}
      className={className}
    >
      {children}
      <span className={styles.iconWrapper}>{Icon}</span>
      <span className="sr-only">(opens in new tab)</span>
    </a>
  );
}
